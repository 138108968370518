import { v4 as uuidv4 } from 'uuid';
import img5 from '../../assets/img/portfolio/cases1.jpg';
import img1 from '../../assets/img/portfolio/project1.jpg';
import img2 from '../../assets/img/portfolio/project2.jpg';
import img3 from '../../assets/img/portfolio/project3.jpg';
import img4 from '../../assets/img/portfolio/project4.jpg';

const projectFilterTwoData = [
    {
        id: uuidv4(),
        image: img1,
        name: 'Rosalina D.',
        price: '$56,000',
        heading: 'Rosali Office Design',
        category: 'bedroom',
    },

    {
        id: uuidv4(),
        image: img2,
        name: 'Rosalina D.',
        price: '$56,000',
        heading: 'Rosali Office Design',
        category: 'floor',
    },

    {
        id: uuidv4(),
        image: img2,
        name: 'Rosalina D.',
        price: '$56,000',
        heading: 'Rosali Office Design',
        category: 'home',
    },

    {
        id: uuidv4(),
        image: img3,
        name: 'Rosalina D.',
        price: '$56,000',
        heading: 'Rosali Office Design',
        category: 'bedroom',
    },

    {
        id: uuidv4(),
        image: img3,
        name: 'Rosalina D.',
        price: '$56,000',
        heading: 'Rosali Office Design',
        category: 'home',
    },

    {
        id: uuidv4(),
        image: img4,
        name: 'Rosalina D.',
        price: '$56,000',
        heading: 'Rosali Office Design',
        category: 'office',
    },

    {
        id: uuidv4(),
        image: img5,
        name: 'Rosalina D.',
        price: '$56,000',
        heading: 'Rosali Office Design',
        category: 'bedroom',
    },
];

export default projectFilterTwoData;
